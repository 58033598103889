
.wrapper {
  height: 0px;
  display: flex;
  flex-direction: row;
  background-color: transparent !important;
  align-items: center;
  line-height: 0px;
  z-index: 1;
  justify-content: flex-start;

  :global {
    .ant-divider {
      background-color: @divider-background;
      height: 70%;
    }

    .ant-menu {
      line-height: 0px;
      background-color: @primary-color;
    }
    .ant-menu-overflow{
      align-items: center;
    }
  }

}

.switcher{
  :global{
    ant-typography{
      font-size: 7px !important;
    }
  }
}

@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";