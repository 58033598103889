@toolbox_width: 30px;

.toolboxDrawer {
    width: @toolbox_width;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: @topbar-height+50px;
    right: 5px;
    z-index: 999;
    height: auto;
    // width: 60px;
    float: right;
    overflow: hidden;
    align-items: center;
    transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -webkit-transition: width 1s ease-in-out;

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 14px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 6px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }


    svg {
        height: 20px;
        width: 20px;
        margin: 2px 3px 0px 0px;
        border-radius: 3px;
        border: 1px solid #444;

        &:hover {
            // border: 2px solid @blue-7;
            scale: 1.1;
            background-color: #666;
        }
    }

    .btnHighlight {
        height: 20px;
        width: 20px;
        margin: 2px 3px 0px 0px;
        border-radius: 3px;
        border: 1px solid #444;
        scale: 1.2;
        background-color: darken(@blue-6, 10);
    }



}

:global {
    .ant-tooltip-inner {
        font-size: 10px;
    }
}




.btnApply {
    width: @toolbox_width;
    height: @toolbox_width;
    border: none;
    background-color: transparent !important;
    margin-top: 12px;
    padding: 0;

    svg {
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
        background-color: transparent !important;
        border: none;

        color: @green-5;

        &:hover {
            border: 1px solid #eee;
            scale: 1.0;
            // background-color: transparent !important;
            // opacity: 0.9;
        }

    }

    .btnActive{
        color: @red-6;
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";