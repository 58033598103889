.container {
    display: flex;
    color: black;
    background-color: chocolate;
    flex-direction: column;
}

.description {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    justify-content: center;
    align-items: center;
    background-color: gold;
}

.charts {
    margin: 20px 0px;
    padding: 5px;
    background-color: firebrick;
}

.chartWrapper{
    border: 1px solid black;
    background-color: honeydew;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";