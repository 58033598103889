.container {
  position: relative;
  display: flex;
  flex-direction: row;
  // margin: 5px;
  // padding: 5px;
  // padding-top: 15px;
  width: auto;
  height: auto;
  overflow-y: auto;
  // overflow-x: auto;
  border-radius: 10px;
  justify-content: space-around;
  align-items: flex-start;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 0px;
  padding: 0px;
  color: white;
}

.title {
  color: aliceblue;
  background-color: aqua;
}

.container {
  display: flex;
  flex-direction: column;
}

.containerTitle {
  color: white;
}

.containerBody {
  td:nth-child(2n+1) {
    clear: left;
    padding-right: 20px; /* You can edit this line and add as per your style */
  }
}

@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";