
.wrapper{
    position: fixed;
    bottom: 0;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.servicesIsActive {
    right: 220px;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";