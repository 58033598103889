@btn-size: 25px;
@btn-deacivate-dim-percentage: 0.3;

.wrapper {
    :global {
        .ant-card {
            display: flex;
            flex-direction: column;
            width: 200px;
            border-right: 2px solid @divider-background;
            border-top-left-radius: 10px;
            min-width: 100px;
            max-width: 300px;
            background-color: @primary-color;
        }

        .ant-card-body {
            padding: 5px;
        }

        .ant-divider {
            margin: 0 0 3px 0 !important;
            border-bottom: 1px solid @divider-background;
            line-height: 15px;
        }
        .ant-typography {
            font-size: 12px;
            line-height: 12px;
            margin-top: 5px;
            color: @text-color-dark;
        }
    }
}

.firstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.secondRow {
    display: flex;
    flex-direction: row;
}

.thirdRow {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
    
    :global{
        .ant-progress{
            margin: 5px 0 0 0 !important;
            padding: 0 0 0 0 !important;
            color: @text-color-dark;
            
        }
        .ant-progress-text{
            font-size: 12px;
            color: @text-color-dark;
            line-height: 0px;
        }
        .ant-progress-steps-item{
            background-color: @text-color-dark;

        }
        .ant-tag{
            font-size: 14px;
            padding: 0 0 0 0 !important;
            margin: 0 0 0 0 !important;
            border: none !important;
            color: @text-color-dark !important;
            background-color: @primary-color;
            line-height: 0px;
        }
        .ant-typography{
            line-height: 0px;
        }
    }
}


.lastRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: @primary-color  !important;
    border-top: 1px dashed @divider-background;
    padding-top: 3px;
    :global {
        .ant-btn {
            // background-color: lighten(@primary-color, 10%) !important;
            background-color: @primary-color  !important;
            padding: 0 !important;
            margin: 0 !important;
            border: none;
            border-radius: 7px;
            line-height: 0px;
            height: @btn-size;
            width: @btn-size;
        }

        .ant-btn:hover {
            filter: brightness(@btn-deacivate-dim-percentage);
            -webkit-filter: brightness(@btn-deacivate-dim-percentage);
            -moz-filter: brightness(@btn-deacivate-dim-percentage);
            -o-filter: brightness(@btn-deacivate-dim-percentage);
            -ms-filter: brightness(@btn-deacivate-dim-percentage);
        }

        svg {
            color: @text-color-dark;
            width: 100%;
            height: 100%;
        }
    }

    .btnActive {
        svg {
            color: @green-5;
            width: 100%;
            height: 100%;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";