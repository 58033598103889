@transform_flag: 1;
@sidebar_width: 250px;
@sidebar_top_offset: 14px;
@sidebar_btn_top_offset: -3px;
// @sidebar_btn_x_offset: -10px;
// @sidebar_btn_y_offset: -19px;

.wrapper {
    position: fixed;
    z-index: 2;
    top: @topbar-height + @sidebar_top_offset;
    left: 0;
    max-width: 600px;
    min-width: @sidebar_width;
    height: auto;
    background-color: @primary-color;
    color: @text-color-dark  !important;
    margin-right: 2px;
    box-shadow: 7px 7px 15px #222;
    max-height: 300px;
    overflow: auto;
    border: 1px solid gray;

    ul {
        border-top-left-radius: 10px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: transparent;
    }

    :global {
        .ant-btn {
            margin: 0;
            padding: 0;
            height: 15px;
            width: 14px;
            line-height: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;
            font-size: 18px;


            path {
                stroke: @text-color-dark;
            }

            &:hover {
                background-color: transparent;
                outline: none !important;

                svg {
                    border: 1px solid @box-border-dark;
                }

                path {
                    stroke: @purple-5;
                }
            }

            &:focus {
                background-color: transparent;
                outline: none !important;

                path {
                    stroke: @text-color-dark;
                }
            }
        }

        .ant-tree {
            background-color: inherit;
            color: inherit;
            padding-bottom: 10px !important;
        }


        .ant-tree-node-content-wrapper {
            transform: scale(@transform_flag, 1);

            &:focus {
                background-color: transparent !important;
            }

            &:hover {
                background-color: transparent !important;
            }

            &:visited {
                background-color: transparent !important;
            }
        }

        .ant-tree-treenode {
            margin: 0 !important;
            padding: 0 !important;
            flex-direction: row !important;
            width: 100% !important;
        }

        .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background-color: transparent !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .ant-tree-switcher {
            background-color: transparent !important;

            // svg {
            //     transform: scale(1) !important;
            // }
        }

        .ant-tree-indent {
            padding-left: 5px !important;
        }
    }

}

.hide {
    visibility: hidden;
}


.dividerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // border-top: 1px solid @box-border-dark;
    line-height: 12px;
    height: 12px;
    transform: scale(@transform_flag, 1);

    :global {
        .ant-typography {
            font-size: ceil(@font-size-base * 0.6);
            color: inherit !important;
        }
    }
}

.divider {
    float: left;
    line-height: 0px;
    width: 30%;
    margin-bottom: 5px !important;
    border-bottom: 1px dashed @divider-background;

}


.mainLayers {
    background-color: lighten(@primary-color, 5%);
}


.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    max-width: 300px;
    // .rowButtons {
    //     // margin-left: auto;
    //   }

    .rowTitle {
        // padding-right: 30px;
        font-size: ceil(@font-size-base * 0.9);
    }

    :global {
        .ant-btn {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
}

.hideBtn {
    position: fixed;
    z-index: 2;
    top: @topbar-height + @sidebar_btn_top_offset;
    left: 0;
    height: auto;
    background-color: lighten(@primary-color, 70%);
    color: @primary-color;
    font-family: monospace;
    font-size: 9px;
    font-weight: bold;
    padding: 0px 3px 0px 3px;
    text-align: center;
    margin: 0px;
    z-index: 2;
    border-radius: 5px 5px 0px 0px;
    // -moz-transform: rotate(-90deg);
    // -ms-transform: rotate(-90deg);
    // -o-transform: rotate(-90deg);
    // -webkit-transform: rotate(-90deg);
    // transform-origin: bottom right;
}

.slideLeft {
    left: 0;
    color: lighten(@primary-color, 70%);
    background-color: @primary-color;
}



@keyframes moveBtn {
    0% {
        transform: translateX(-200);
        // width: var(--w1);
    }
    50% {
        transform: translateX(-100);
        // width: var(--w1);
    }
    100% {
        transform: translateX(0);
        // width: var(--w2);
    }
}


@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";