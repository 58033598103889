.widgetContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  color: white;
}

.generateIdfBtn {
  // position: absolute;
  left: 10%;
  transform: translateX(-10%);
  padding: 5px 10px;
  line-height: 0.8em;
  //   margin-top: 10px;
  background-color: #009579;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
  height: 30px;

  &:hover {
    background-color: #009579cc;
  }
}

.generateIdfBtn:active {
  background: #007a63;
}

.generateIdfBtnTxt {
  font: bold auto 'Quicksand', san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.loadingTxt {
  font: bold auto 'Quicksand', san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.widgetDescription {
  position: relative;
  // top: 10%;
  margin: 10% 10% 10% 10%;
  text-justify: distribute;
  font-size: auto;
  text-align: justify;
  text-justify: inter-word;
  font-weight: bolder;
}

.descriptionParagraph {
  margin: 0% 10% 5% 10%;
  padding: 0;
  font-weight: normal;
}


.downloadIdfBtn {
  position: absolute;
  right: 10%;
  transform: translateX(10%);
  padding: 5px 10px;
  line-height: 0.8em;
  //   margin-top: 10px;
  background-color: #5667aa;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
  // min-width: 40%;
  // max-width: 60%;
  height: 30px;
  
  svg {
    stroke: #fff;
  }

  &:hover {
    background-color: #5667aacc;
  }
}

.selectionSummary {
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  max-width: 80%;
  border-top: 2px dashed gray;
  padding: 15px;

  td {
    padding-left: 15px;
  }
//   tr {
//     float: left;
//   }

//   tr:nth-child(2n + 1) {
//     clear: left;
//     padding-right: 20px; /* You can edit this line and add as per your style */
//   }
}

.buttonsContainer{
  // position: absolute;
  display: flex;
  flex-direction: column;
}

@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";