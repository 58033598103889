@drawer_height: 340px;

.wrapper{

    position: fixed;
    z-index: 3;
    bottom: 0;
    width: 80%;
    left: 10%;
    max-height: @drawer_height;
    min-height: @drawer_height;
    border-radius: 10px 10px 0px 0px;
    border: 2px solid lighten(@primary-color, 5);
    background-color: @primary-color;
    transition: bottom .3s cubic-bezier(0.820, 0.085, 0.395, 0.895);

    .button{
        position: absolute;
        padding: 0;
        margin: 0;
        width: 20px;
        height: 15px;
        top: -2%;
        left: 50%;
        background-color: lighten(@primary-color, 20);
        border: 1px solid #fff;
        border-radius: 7px;
        &:hover{
            background-color: lighten(@primary-color, 50);
            scale: 1.1;
            border: 1px dashed #fff;
        }

        svg{
            position: relative;
            padding: 0;
            margin: 0;
            width: 15px;
            height: 15px;
            margin-bottom: 3px;
            transition: .2s cubic-bezier(0.820, 0.085, 0.395, 0.895);
        }
    }

    .buttonHidden{
        svg{
            transform: rotate(180deg);
        }
    }
}

.inactive{
    border: 3px dashed #fff;
    bottom: -0.97*@drawer_height;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";