.wrapper {
    
    :global {
        .ant-input-number {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            border: 1px solid lighten(@box-border-dark, 0%);
            border-bottom: none;
            font-size: 10px !important;
            // min-width: 10% !important;

            &:hover {
                border: 1px solid darken(@box-border-dark, 30%);
            }
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";