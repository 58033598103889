.wrapper {
    position: absolute;
    left: 40%;
    z-index: 2;
    bottom: 40px;
    width: 300px !important;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 50px;
    background-color: lighten(@primary-color, 10%);
    margin: 0 auto;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid @box-border-dark;

    :global {
        .ant-slider {
            // margin: 10px 10px 10px 20px;
        }

        .ant-slider-track {
            background-color: @red-5;
        }

        .ant-btn {
            position: absolute;
            top: 30%;
            left: 3%;
            background-color: @blue-3;
            height: 42%;
            width: 8%;
            padding: 0px;
            margin: 0px;

            svg {
                font-size: 20px;
            }
        }

    }
}

:global {
    .ant-tooltip {
        background-color: transparent !important;
    }

    .ant-tooltip-inner {
        min-width: 150px !important;
    }
}

.speedSlider {
    :global {
        .ant-slider-track {
            background-color: @green-5;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";