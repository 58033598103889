@sidebar-width: 30px;


.costumLayout {
    height: 100vh;

    :global {
        .ant-layout {
            margin-left: @sidebar-width;

        }

        .ant-layout-content {
            height: 100vh;
        }
    }

}

.mapWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}


.layerManager {
    opacity: 0.4;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";