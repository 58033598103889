.wrapper {
    
    :global {

        .ant-select-selector {
            background-color: @primary-color  !important;
            border: 1px solid @box-border-dark  !important;
            color: #ccc !important;
            font-size: 10px;
        }

        .ant-select-selection-item {
            background-color: #000 !important;
            color: @text-color-dark !important;

            svg {
                color: @text-color-dark !important;

            }
        }

        .ant-select-selection-overflow-item {}
    }
}

.dropdown{
    :global {
        .ant-select-item{
            
            &:hover{
                background-color: lighten(@primary-color, 30%);
            }
            &:focus{
                background-color: lighten(@primary-color, 30%);
            }
        }
        .ant-select-item-option-active{
            background-color: lighten(@primary-color, 30%);
            &:hover{
                background-color: lighten(@primary-color, 30%);
            }
            &:focus{
                background-color: lighten(@primary-color, 30%);
            }
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";