.wrapper{
    position: fixed;
    bottom: 105px;
    left: 25px;
    // width: 50px;
    margin: 0;
    padding: 0;

    svg{
        font-size: 20px;
        color: white;
        &:hover{
            transform: scale(1.2);
            border: 1px solid white;
            border-radius: 11px;
        }
    }

}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";