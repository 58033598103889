@import url(https://fonts.googleapis.com/css2?family=Nova+Mono&family=Share+Tech+Mono&family=Varela+Round&display=swap);

.titleWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #000;
  width: 110px;
  height: 50px;
  margin: 1px 2px;
  border: none;
  border-radius: 10px;
}

.title {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  color: @gold-6;
  font-size: 22px;
  font-weight: bolder;
  line-height: 0px;
  // font-family: 'Nova Mono', monospace;
  // font-family: 'Share Tech Mono', monospace;
  font-family: 'Varela Round', sans-serif;
  animation-name: colorchange;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  align-items: center;
  // margin-right: 5px;
  justify-content: flex-start;
  min-width: 100px;

  @keyframes colorchange {
    0% {
      color: @blue-3;
    }

    20% {
      color: @blue-5;
    }

    30% {
      color: @gold-8;
    }

    50% {
      color: @red-6;
    }

    70% {
      color: @purple-4;
    }

    80% {
      color: @blue-5;
    }

    100% {
      color: @blue-3;
    }
  }

  :global {
    .ant-divider {
      background-color: @divider-background;
      line-height: 0px;
      height: 30px;
      margin-left: 20px;

      @media @desktop {
        margin-left: 40px;
      }
    }
  }
}

// .titleStart {
//   margin: 0 5px 0px 5px;
// }

// .titleEnd {
//   margin: 0 0px 0px 0;
// }

.titleStart {
  position: absolute;
  margin: 0px 0px 0px 0px;
  top: 13px;
  left: 5px;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: 18%;
    width: 90%;
    height: 15px;
    border-bottom: 2px solid white;
    border-right: 1px dashed white;
    z-index: -1;
  }
}

.titleEnd {
  position: absolute;
  margin: 0px 0px 0px 0px;
  top: 37px;
  left: 32px;
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: 17%;
    width: 90%;
    height: 15px;
    border-top: 2px solid white;
    border-left: 1px dashed white;
    z-index: -1;
  }
}

.layersLogo {
  position: absolute;
  width: 18px;
  top: 3px;
  left: 74px;
}

.cityLogo {
  position: absolute;
  width: 16px;
  top: 26px;
  left: 8px;
}


@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";