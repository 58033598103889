
.wrapper {
  // height: 100%;
  // display: flex;
  // flex-direction: row;
  // align-items: centre;

  .menu {
    // display: flex;
    // position: relative;
    // flex-direction: row;

    // li {
    //   margin-left: 30px;
    // }

    :global {
      // .ant-divider {
      //   background-color: @divider-background;
      //   // line-height: 0px;
      //   height: 30px;
      // }

      .ant-menu-overflow {
        background-color: @primary-color;
        &:focus {
          background-color: @primary-color;
        }
        &:hover {
          background-color: @primary-color;
        }
      }

      .ant-menu-overflow-item {
        background-color: @primary-color;
        // width: 20px;
        &:focus {
          background-color: @primary-color;
        }
        &:hover {
          background-color: @primary-color;
        }
      }

      .ant-menu-item-selected {
        background-color: @primary-color;

        &:focus {
          background-color: @primary-color;
        }
      }
    }
  }

  .about {
    :global {
      .ant-btn {
        color: #888 !important;
      }
    }
  }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";