.sidebarMainServices {
    color: lighten(@primary-color, 80);
    list-style: none;
    font-weight: 600;
    padding: 0;
    margin: 0;
    font-family: 'Quicksand';
}

.sidebarSecondaryServices {
    list-style: none;
    cursor: pointer;
    padding: 0;
    li {
        padding-left: 40px;
        margin-left: -20px;
        margin-right: -20px;
        color: @text-color;
        border: solid 1px rgba(0,0,0,0);
        &:hover{
            border-top: solid 1px @text-color;
            border-bottom: solid 1px @text-color;
            background-color: lighten(@primary-color, 10);
            cursor: pointer;
        }
    }
    &:nth-of-type(even){
        background-color: #1982c4;
    }
}

.slideMenu {
    position: fixed;
    right: 0px;
    height: 95%;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    padding-top: 30px;
    background-color: #eeeff2;
    z-index: 1;
}

.slideBody {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin-top: 40px;
    right: 0;
    height: 94%;
    min-width: 220px;
    max-width: 320px;
    background-color: @primary-color;
    right: 0px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    transition: right .3s;
    transition-timing-function: cubic-bezier(.42, 0, 1, 1);
    z-index: 2;
    overflow: auto;
    padding: 20px;
    color: white;
}

.menuBody {
    color: white;
    display: flex;
    flex-direction: column;
    button {
        position: absolute;
        bottom: 2%;
        right: 30px;
    }
    h3, h4 {
        margin-top: 10px;
        margin-bottom: 40px;
        align-self: center;
        color: white;
    }
}

.leftSlide {
    right: 150px;
}

.slideBtn {
    position: absolute;
    bottom: 100px;
    right: 30px;
    margin: 2px 3px;
    z-index: 2;
    width: 40px;
    height: 20px;
    font-size: 18px;
    font-weight: 900;
    background-color: transparent;
    color: @text-color;
    padding: 0px 2px;
    line-height: 0.0;
    outline: none;
    border: none;
    &:hover{
        transform: scale(1.04);
        cursor: pointer;
    }
}

.rowContainer {
    background-color: #d9dadf;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 30px;
    margin: 5px 0px;
    padding: 20px 10px;
    color: black;

    &:hover {
        background-color: lighten(@primary-color, 10);
        cursor: pointer;
        transform: scale(1.02);
    }

    .icon {
        width: 25px;
        height: 25px;
    }

    .text {
        font-size: small;
        font-weight: 700;
        margin-right: 15px;
    }

    .iconText {
        font-size: large;
        font-weight: 900;
    }
}

.activeMenu {
    background-color: #7482d4;
    transform: scale(1.02);

    &:hover {
        background-color: #7482d4;
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";