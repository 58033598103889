.wrapper {
    background-color: #00000099;
    line-height: 0 !important;
    border: 1px dashed @box-border-dark;
    padding: 0 5px 0 0 !important;

    // :global {}
}

:global {
    .ant-select-dropdown {
        color: @text-color-dark  !important;
        background-color: @primary-color  !important;
        font-size: 10px !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 5px 0 !important;
    }

    .ant-select-selection-item {
        border: 3px solid @text-color-dark;
    }
    
}

.closebtn {
    position: absolute;
    top: -22px;
    right: 3px;
    margin: 0 0 0 0;
    background-color: @primary-color;

    &:hover {
        color: @red-4;
        transform: scale(1.2) !important;
        -webkit-transform: scale(1.2) !important;
        -ms-transform: scale(1.2) !important;
        -webkit-transition: color 200ms linear;
        -ms-transition: color 200ms linear;
        transition: color 200ms linear;
    }
}

.inputGroup {
    position: absolute;
    display: flex;
    align-items: flex-end;
    left: 0 !important;
    bottom: 0;

    :global {

        .ant-tree-select {
            min-width: 150px;
            min-height: 20px;
            max-height: 50px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: @primary-color;
            border-top: 1px solid @box-border-dark;
            border-right: 1px solid @box-border-dark;
            border-left: none;
            border-bottom: none;
        }

        .ant-select-selector {
            width: 100% !important;
            font-size: 11px !important;
            height: 100% !important;
        }

        .ant-select-selection-item {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            font-size: 10px !important;
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
            height: 20px !important;
            border: 1px solid @box-border-dark  !important;
            border-radius: 5px !important;
            line-height: 0;
        }

        .ant-select-selection-item-content {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            font-size: 9px !important;
            margin: -2px 0 0 1px !important;
            padding: -2px 0 0 0 !important;
        }

        .ant-select-selection-overflow-item {
            border: none !important;
            margin: 0 2px 0 0 !important;
        }

        .ant-select-multiple {
            svg {
                color: @text-color-dark  !important;
                width: 80% !important;
                padding: 0 0 0 2px !important;
                margin: 0 0 0 0 !important;
            }
        }

        .ant-switch{
            border: 1px solid @box-border-dark;
            margin: 0 0 2px 5px;
        }
        .ant-switch-handle:before{
            background-color: @box-border-dark;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";