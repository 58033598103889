@btn-deacivate-dim-percentage: 0.25;

.wrapper {

    :global {
        .ant-card {
            border-top-left-radius: 10px;
            min-width: 110px;
            max-width: 200px;
            background-color: @primary-color;
        }
        .ant-card-body {
            padding: 5px;
        }
        .ant-divider {
            margin: 0 0 3px 0 !important;
            border-bottom: 1px dashed @divider-background;
            line-height: 15px;
        }
        .ant-divider-inner-text {
            color: fade(@magenta-4, 75%);
            font-size: 10px;
        }
    }
}

.row {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    align-items: center;
    width: auto;
    justify-content: stretch;

    :global {
        .ant-btn {
            background-color: inherit;
            border: none !important;
            margin: 3px 5px 0px 5px !important;
            width: 25px;
            height: 25px;
            border-radius: 4px;
            svg {
                color: #000;
                width: 100%;
                
            }
        }

        .ant-col {
            white-space: nowrap;
            // margin: 2px 1px 0px 0px !important;
            // padding-right: 10px;
        }
        .ant-typography{
            color: @text-color-dark;
        }
    }
    .agentName{
        padding-right: 10px;
    }
    .agentCount{
        padding-left: 5px;
    }

    .btnActive{
        filter: brightness(@btn-deacivate-dim-percentage);
        -webkit-filter: brightness(@btn-deacivate-dim-percentage);
        -moz-filter: brightness(@btn-deacivate-dim-percentage);
        -o-filter: brightness(@btn-deacivate-dim-percentage);
        -ms-filter: brightness(@btn-deacivate-dim-percentage);
    }
    
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";