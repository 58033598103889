.wrapper {
}

.chart {

    svg {
        background-color: @primary-color;
        overflow: visible;
        margin: 20px 20px 20px 30px;
    }
}

.dividerTools {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 10%;
    min-width: 100px;
    right: 1%;
    bottom: 15%;
    margin: 5px 10px;
    padding: 0px 2px;
    border-top: 1px dashed lighten(@primary-color, 30);

    p {
        margin: 0 0 0 0;
        justify-content: stretch;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

}

.dividerButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
        margin: 5px 5px 3px 0px;
        background-color: white;
        font-size: 18px;        
    }

}

.dividerBtn {
    background-color: transparent;
    border-radius: 9px;
    border: 1px solid white;
    &:hover {
        background-color: gray;
        scale: 1.1;
    }
}



.btnHighlight {
    animation: blinker 0.5s ease-in-out infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.6;
    }
}

.dividerBtnPopup {
    margin-top: 5vh;
    color: black;
    font-size: 10px;
    padding: 0;

    svg {
        stroke: blue;
        font-size: 12px;
        font-weight: 800;
        margin-right: 3px;
        margin-bottom: -2px;
        stroke-width: 3px;
    }

    :global {
        .ant-message-notice-content {
            padding: 0px 5px;
            margin: 0;
        }
    }
}




.ratioEditorWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 1%;
    bottom: 20%;
    margin: 5px 10px;
    padding: 0px 2px;
    border-top: 1px dashed lighten(@primary-color, 30);
    width: 150px;
    
    .ratioTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0px 5px;
            padding: 0px;

            p {
                margin: 0;
                padding: 0;
                justify-content: stretch;
                font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-size: 14px;
                font-weight: 500;
            }

    }
    
    .ratioEditor {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 5px;
        margin-bottom: 10px;

        button {
            width: 30px;
            height: 18px;
            font-size: 10px;
            font-weight: bold;
            background-color:white;
            color: black;
            margin: 0;
            padding: 0;
            margin-top: 2px;
            border: None;
            border-radius: 8px;
            &:hover{
                background-color: lighten(@primary-color, 60);
            }
        }
    }
}


.calcButton {
    position: absolute;
    bottom: 0%;
    right: 0%;
    // width: 30px;
    margin: 10px 15px;
    font-size: 13px;
    font-weight: 800;
    font-family:'Gill Sans';
    button {
        background-color: #222;
        color: white;
        border-radius: 10px;
        &:hover{
            transform: scale(1.05);
            background-color: #000;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";