.basemapDrawer {
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 8px;
    left: 8px;
    z-index: 999;
    // height: 50px;
    width: 60px;
    float: left;
    overflow: hidden;
    align-items: center;
    transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -webkit-transition: width 1s ease-in-out;


    img {
        height: 60px;
        width: 60px;
        margin: 0px 3px 0px 0px;
        border-radius: 5px;
        border: 1px solid white;

        &:hover {
            border: 2px solid @blue-7;
        }
    }
}

.basemapDrawerDark {
    img {
        border: 2px solid black;
    }
}

.hide {
    visibility: hidden;
}

.overlayLabel {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10%;
    left: 14px;
    color: @divider-background;
    justify-content: center;
    align-items: center;
    line-height: 25px;
}


.overlayText {
    font-size: 12px;
    font-weight: 400;
    color: white;
}

.overlayTextDark {
    font-size: 12px;
    font-weight: 400;
    color: black;
}

.overlayIcon {
    svg {
        height: 20px;
        color: white;
    }
}

.overlayIconDark {
    svg {
        color: black;
    }
}

.basemapDrawer:hover {
    width: auto;
    background-color: @text-color-dark  !important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    border-radius: 5px;

    .overlayLabel {
        display: none;
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";