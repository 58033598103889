@spinnerSize: 44px;
@spinnerColor: @blue-5;

.container {
    position: fixed;
    z-index: 99999999;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    right: 50vw;
    :global{
        .ant-spin-dot{
            width: @spinnerSize;
            height: @spinnerSize;
        }
        .ant-spin-dot-item{
            background-color: @spinnerColor !important;
            border: 1px solid #555;
            width: 10px !important;
            height: 10px !important;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";