.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 50vw;
    min-height: 5vh;
    max-height: 80vh;
    padding: 15px 10px;
    border: 2px solid @blue-4;
    border-radius: 10px;
    overflow: auto;
    background-color: #fff;

    button {
        position: absolute;
        top: 3px;
        right: 3px;
        line-height: 0px;
        width: 12px;
        height: 12px;
        background-color: transparent;
        color: #000;
        border: none;
        font-size: 10px;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        
        &:hover {
            border: none;
            background-color: #fff;
            -webkit-filter: invert(100%);
            filter: invert(100%);
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";