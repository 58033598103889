.wrapper {
    background-color: @primary-color;
    padding: 5px 0px;
    border-radius: 8px;
    position: fixed;
    bottom: 70px;
    left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // flex-grow: 1 !important;
    justify-content: space-between;
    font-size: 8px !important;
    // border: 1px solid @box-border-dark  !important;
    // border-radius: 3px;
    min-width: 50px;

    :global {

        .ant-switch {
            background-color: @primary-color  !important;
            border: 1px solid #ccc !important;
            // max-width: 35px !important;
            transform: scale(0.8) !important;
            line-height: 0px;
            margin: 0px !important;
            padding: 0px !important;
        }

        .ant-switch-handle:before {
            line-height: 0px;
            background-color: @red-5  !important;
            margin-bottom: 2px;
        }

        .ant-switch-checked {
            .ant-switch-handle:before {
                background-color: @green-5  !important;
            }
        }

        .ant-typography {
            color: @text-color-dark  !important;
            font-size: inherit !important;
            // line-height: 0px;
        }

    }
}

.verticalAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-0%, -5%);

    :global {
        .ant-typography {
            color: @text-color-dark  !important;
            font-size: inherit !important;
            margin-top: 2px;
            line-height: 5px;
        }
    }
}


@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";