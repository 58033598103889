@transform_flag: 1;

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    max-width: 300px;
    // .rowButtons {
    //     // margin-left: auto;
    //   }

    .rowTitle {
        // padding-right: 30px;
        font-size: ceil(@font-size-base * 0.9);
    }

    :global {
        .ant-btn {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";