.wrapper {
    position: fixed;
    top: @topbar-height+5vh;
    right: 5vw;
    display: flex;
    flex-direction: row;
    padding: 0px 5px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    // padding-top: 15px;

    ul {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        list-style: none;
        padding: 20px 10px;
        margin: 0;
        height: auto;
        // border-left: 1px dashed rgba(255,255,255,0.3)
    }

    li {
        margin-top: 10px;
    }

    span {
        height: 7px;
        display: block;
        color: #fff;
        padding: 0px 0px 0px 0px;
        margin: 10px 5px 0px 5px;
        text-align: center;
        font-weight: 300;
        font-size: 12px;
    }
}

.title {
    position: absolute;
    top: 5px;
    left: 50%;
    font-weight: 900;
    font-size: 14px;
    transform: translate(-50%, 0%);
}

.closeBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: @blue-2;
    border-radius: 7px;
    &:hover {
      transform: scale(1.2)  
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";