.topmenu {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 100%;
}

.panelTrigger {
  cursor: pointer;
  position: fixed;
  top: 0px;
  right: 0px;
  color: black;
  margin: 2px;
  padding: 2px;
  background-color: transparent;
  font-weight: bold;
  font-size: 16px;
  :global(.ant-btn) {
    background-color: @primary-color;
    &:hover {
      background-color: #444 !important;
      border-color: #fff !important;
      color: #fff;
    }
  }
}

@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";