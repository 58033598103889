.container {
    color: #000;
}

.modalContainer {
    background-color: rgba(44, 44, 44, 0.3);

    :global {
        .ant-modal-header {
            display: none;
        }

        .ant-modal-content {
            margin-top: 30px;
            color: #000;
        }

        .ant-modal-body {
            padding: 20px 20px 20px 20px;
        }

        .ant-btn {
            color: #000;
        }
    }
}

.visitButton {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;
    border-radius: 5px;
    background-color: transparent;

    
    :global {
        .ant-btn {
            border-radius: 5px;
            border: 1px solid #000;
            &:hover{
                border-radius: 15px;
                font-weight: bold;
            }
        }
    }
}


@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";