.container {
    
    background-color: gray;
    height: 100%;

}

.canvas {
    background-color: rgb(224, 133, 133);
    height: 80%;
}

.subSection {
    background-color: rgb(97, 88, 219);
    height: 10%;
}

.noSelection {
    margin: 20%;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";