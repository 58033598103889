.container {
    position    : fixed;
    top         : @topbar-height + 2px;
    right       : 0;
    // max-width: 300px;
}

.cardContainer {
    position        : absolute;
    right           : 100px;
    max-width       : 300px;
    background-color: green !important;

}

.geomSelector {
    position  : absolute;
    top       : 30px;
    right     : 10px;
    min-width : 40px;
    max-height: 50px;

    :global {
        .ant-select {
            color    : white !important;
            font-size: 11px !important;
            min-width: 130px !important;
            border   : none !important;

            svg {
                font-size   : 14px;
                margin-right: 5px;
                color       : violet;
            }
        }

        .ant-select-selector {
            border          : 0px solid @text-color-dark  !important;
            background-color: lighten(@primary-color, 30) !important;
        }

        .ant-select-selection-item {
            border: none !important;

            svg {
                margin-top: 4px !important;
                font-size : 20px;
            }
        }
    }
}

.geomDropdown {
    background-color: lighten(@primary-color, 30) !important;
    min-width       : 100px !important;
    font-size       : 8px;

    :global {
        .ant-select-item {

            font-size: 10px;

            &:hover {
                color           : black;
                background-color: lighten(@primary-color, 30) !important;
            }

            &:focus {
                color           : red;
                background-color: @blue-5  !important;
            }

            svg {
                font-size   : 16px;
                margin-right: 5px;
            }
        }

        .ant-select-item-option-active {
            background-color: lighten(@primary-color, 20) !important;
        }

    }
}



.closebtn {
    position        : absolute;
    right           : 10px;
    max-width       : 30px;
    background-color: transparent;
    color           : #ffffffaa;

    font-size    : 22px;
    border-radius: 15px;

    &:hover {
        color: #ffffffff;
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";