.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: @topbar-height;
    right: 0;
    align-items: center;
    // border: 1px solid #555;

    :global {
        .ant-btn {
            background-color: transparent;
            margin: 2px;
            margin-bottom: 6px;
            padding: 3px;
            font-size: ceil(@font-size-base *0.7);
            line-height: 0;
            height: 16px;

            svg {
                font-size: 16px;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            &:hover {
                color: #444;
                border: 1px solid #444;
                background-color: #fff;
            }
        }

    }
}

.charts {
    margin-top: 10px;
    font-size: ceil(@font-size-base *0.8);

    :global {
        .ant-divider {
            position: absolute;
            background-color: @box-border-dark;
            margin: 0;
            width: 30%;
        }
    }
}
.d3Chart{
    max-width: 300px;
    min-width: 100px;
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";