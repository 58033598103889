:global {
    .ant-select-tree {
        color: @text-color-dark  !important;
        background-color: @primary-color  !important;
    }

    .ant-select-dropdown {
        color: @text-color-dark  !important;
        background-color: @primary-color  !important;
        font-size: 10px !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 5px 0 !important;
    }

    .ant-select-tree-title {
        color: @text-color-dark  !important;
        font-size: 10px !important;
        line-height: 0 !important;
    }

    .ant-select-tree-checkbox-inner {
        line-height: 0;
        background-color: @primary-color  !important;
        height: 2px;
    }

    .ant-select-tree-node-content-wrapper:hover {
        background-color: lighten(@primary-color, 10%) !important;
    }

    .ant-select-tree-checkbox {
        font-size: 10px !important;
        transform: scale(.7) !important;

        &:hover {
            border: 1px solid lighten(@primary-color, 30%);
        }
    }

    .ant-select-tree-checkbox-checked {
        border: 1px solid lighten(@primary-color, 50%);
    }

    .ant-select-tree-checkbox-inner {
        border: 1px solid lighten(@primary-color, 50%);
    }

    .ant-select-tree-switcher-noop {
        visibility: hidden !important;
    }

    .ant-select-tree-treenode {
        max-height: 18px;
        line-height: 0;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;

        &:hover {
            transform: none !important;
        }
    }

    .ant-select-selection-item {
        border: 3px solid @text-color-dark;
    }

    .ant-select-tree-indent {
        display: none;
    }
}

.closebtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px 5px 0 0;
    background-color: @primary-color;

    &:hover {
        color: @red-4;
        transform: scale(1.2) !important;
        -webkit-transform: scale(1.2) !important;
        -ms-transform: scale(1.2) !important;
        -webkit-transition: color 200ms linear;
        -ms-transition: color 200ms linear;
        transition: color 200ms linear;
    }
}

.inputGroup {
    position: absolute;
    display: flex;
    align-items: flex-end;
    left: 0 !important;
    bottom: 0;

    :global {

        .ant-tree-select {
            min-width: 150px;
            min-height: 20px;
            max-height: 50px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: @primary-color;
            border-top: 1px solid @box-border-dark;
            border-right: 1px solid @box-border-dark;
            border-left: none;
            border-bottom: none;
        }

        .ant-select-selector {
            width: 100% !important;
            font-size: 11px !important;
            height: 100% !important;
        }

        .ant-select-selection-item {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            font-size: 10px !important;
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
            height: 20px !important;
            border: 1px solid @box-border-dark  !important;
            border-radius: 5px !important;
            line-height: 0;
        }

        .ant-select-selection-item-content {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            font-size: 9px !important;
            margin: -2px 0 0 1px !important;
            padding: -2px 0 0 0 !important;
        }

        .ant-select-selection-overflow-item {
            border: none !important;
            margin: 0 2px 0 0 !important;
        }

        .ant-select-multiple {
            svg {
                color: @text-color-dark  !important;
                width: 80% !important;
                padding: 0 0 0 2px !important;
                margin: 0 0 0 0 !important;
            }
        }

        .ant-input-number {
            background-color: @primary-color  !important;
            color: @text-color-dark  !important;
            border: 1px solid lighten(@box-border-dark, 0%);
            border-bottom: none;
            font-size: 10px !important;
            // min-width: 10% !important;

            &:hover {
                border: 1px solid darken(@box-border-dark, 30%);
            }
        }
    }
}
@hack: true; @import "/Users/home-imac/Documents/projects/js/scatterplot-animated/src/themes/default-theme.less";